<template>
  <div :class="$style.layout">
    <BjHeader />
    <BjSidebar v-if="pending" />
    <div :class="$style.wrapper" :style="paddingLeft">
      <div v-if="pending && !$route.meta.hideBread" :class="$style.header">
        <BjBread />
      </div>
      <transition name="fade" mode="out-in">
        <div v-if="!pending" :class="$style.loading">
          <a-spin />
        </div>
        <a-config-provider v-else>
          <template #renderEmpty>
            <div class="nodata">
              <img :src="oss + '/manage/media/state/no_data.png'" alt="" />
              <p>暂无数据</p>
            </div>
          </template>
          <router-view :class="$style.view" />
        </a-config-provider>
      </transition>
    </div>
    <transition @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter">
      <div v-if="car" id="file" :class="$style.car">
        <img :src="oss + '/manage/media/assets/upload.png'" alt="" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { BjBread, BjHeader, BjSidebar } from '@/components'

export default {
  name: 'Home',
  components: {
    BjHeader,
    BjSidebar,
    BjBread,
  },
  beforeRouteEnter(to, from, next) {
    if (to.name !== 'login') {
      // 初始请求
      next(vm => vm.fetchMenu())
      next()
    } else {
      next()
    }
  },
  data() {
    return {
      // pending: false,
    }
  },
  computed: {
    ...mapState(['pending', 'car']),
    collapsed: {
      get() {
        return this.$store.state.collapsed
      },
      set(val) {
        this.$store.commit('COLLAPSED', val)
      },
    },
    sideChirdShow: {
      get() {
        return this.$store.state.sideChirdShow
      },
      set(val) {
        this.$store.commit('SIDECHIRDSHOW', val)
      },
    },
    paddingLeft() {
      let left = this.collapsed ? (this.sideChirdShow ? '220px' : '60px') : this.sideChirdShow ? '300px' : '140px'
      return { 'padding-left': left }
    },
  },
  methods: {
    ...mapActions(['fetchMenu']),
    // 动画钩子函数
    // 进入前状态
    beforeEnter(el) {
      el.style.transform = 'scale(1)'
      el.style.left = document.documentElement.clientWidth / 2 + 'px'
      el.style.top = document.documentElement.clientHeight / 2 + 'px'
    },
    // 进入中
    enter(el, done) {
      // 需要调用元素的offset操作,才有过渡的效果,否则会马上实现过渡
      // el.style.left = document.getElementById('car').getBoundingClientRect().left - 150 + 'px'
      // el.style.top = '-' + document.getElementById('xjq').getBoundingClientRect().top + 'px'
      // document.getElementById('xjq').getBoundingClientRect().top + 'px'
      // el.style.top = '-' + document.documentElement.clientHeight / 2 + 'px'
      // el.offsetWidth
      // el.style.transform = `translate(${
      //   document.getElementById('car').getBoundingClientRect().left - document.documentElement.clientWidth / 2 + 250
      // }px,-${document.documentElement.clientHeight / 2 + 200}px)`
      // el.style.transform = `translate(${
      //   document.getElementById('car').getBoundingClientRect().left - document.documentElement.clientWidth / 2 + 50
      // }px,-${document.documentElement.clientHeight / 2}px) scale(0)`
      el.style.left = document.getElementById('car').getBoundingClientRect().left + 100 + 'px'
      el.style.top = 0 + 'px'
      el.style.transform = 'scale(0.5)'
      el.style.transition = 'left 1s cubic-bezier(1,0.3,1,.68), top 1s cubic-bezier(.1,0.1,0.5,.68), transform 1s'
      done()
    },
    // 进入后
    afterEnter() {
      this.$store.commit('CAR', false)
    },
  },
}
</script>

<style lang="less" module>
.layout {
  width: 100%;
  min-height: 100%;
  background-color: #fafafa;
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  padding-top: 60px;
  transition: padding 0.3s;
}

.box {
  background-color: #fafafa;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.header {
  height: 52px;
  padding-left: 14px;
  color: #000;
  font-size: 16px;
  line-height: 52px;
  background: #fff;
  border-bottom: 1px solid #eee;
  border-left: 1px solid #eee;
}

.view {
  position: relative;
  min-width: 1180px;
  min-height: calc(100% - 52px);
  padding: 20px;
  background: #fafafa;
}

.car {
  position: absolute;
  z-index: 99999;
  width: 60px;
  height: 60px;
  border: 1px solid #fff;
  border-radius: 100%;

  img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
  }
}
</style>
